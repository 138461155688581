import { GET_UPLOADED_IMAGE_LIST } from "helpers/jiraya_url";
import CollectionGallery from "./collection_gallery";
import { getUploadedImageList } from "helpers/jiraya_helper";
import { Modal, ModalBody } from "reactstrap";

const MyUploads = (props) => {
  const fetcherUrl = GET_UPLOADED_IMAGE_LIST;
  const fetcher = getUploadedImageList;
  const collection = null;
  return (
    <div>
      <CollectionGallery
        {...props}
        galleryParams={{
          ...props.galleryParams,
          galleryProps: {
            ...props.galleryParams.galleryProps,
            columns: 2,
            rowHeight: 300,
          },
        }}
        isGuestUpload={true}
        getImageParams={{
          fetcherUrl,
          fetcher,
        }}
      />
    </div>
  );
};

const MyUploadModal = ({ isOpenMyUploads, toggleMyUploads, ...props }) => {
  return (
    <Modal
      isOpen={isOpenMyUploads}
      toggle={toggleMyUploads}
      centered
      size="lg"
      className="modal-fullscreen"
    >
      <div className="modal-header position-relative">
        <div className="modal-title">
          <h4>My Uploads</h4>
        </div>
        <button className="btn-close" onClick={toggleMyUploads}></button>
      </div>
      <ModalBody>
        <div className="client-gallery">
          <MyUploads {...props} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export const MyUploadToolbar = ({
  selectedImages,
  isAllImagesSelected,
  toggleAllImageSelection,
  handleOnImageDelete,
}) => {
  return (
    <>
      {selectedImages.length > 0 && (
        <div className="d-flex justify-content-center gap-3 my-upload-toolbar ">
          <div
            className="d-flex align-items-center"
            onClick={toggleAllImageSelection}
          >
            {selectedImages.length === 0 ? (
              <>
                <i className="mdi mdi-checkbox-blank-outline me-1 fs-4" />
                Select All
              </>
            ) : isAllImagesSelected ? (
              <>
                <i className="mdi mdi-checkbox-marked me-1 fs-4" />
                De-Select All
              </>
            ) : (
              <>
                <i className="mdi mdi-checkbox-intermediate me-1 fs-4" />
                Select All
              </>
            )}
          </div>
          <div className="text-danger" onClick={handleOnImageDelete}>
            <i className="mdi mdi-delete me-1 fs-4" />
          </div>
        </div>
      )}
    </>
  );
};

export default MyUploadModal;
