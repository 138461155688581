import { Field, Formik, Form } from "formik";
import { useContext, useEffect, useState } from "react";
import { Label, Input, Col, Row, FormGroup, FormFeedback } from "reactstrap";
import UserContext from "store/guest_user/userContext";
import * as Yup from "yup";

const CompleteProfile = () => {
  const { handleOnUpdateProfile, user } = useContext(UserContext);
  const handleSubmit = (values) => {
    const userProfile = { ...values };
    userProfile.displayName = userProfile.fullName;
    handleOnUpdateProfile(userProfile);
  };

  const ProfileSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is Required"),
    // email: Yup.string().email("Invalid email").required("Email is Required"),
    tnc: Yup.boolean().oneOf([true], "Required"),
  });

  const [defaultData, setDefaultData] = useState({});
  useEffect(() => {
    let registeredData = localStorage.getItem("registration-data");
    if (registeredData) {
      registeredData = JSON.parse(registeredData);
      setDefaultData({ fullName: registeredData.name });
    }
  }, []);

  return (
    <>
      <div className="w-100 text-center complete-profile">
        <div>
          <strong className=" ">Complete your Profile</strong>
        </div>
        <div className="mt-3">
          <Formik
            validationSchema={ProfileSchema}
            initialValues={{
              mobileNumber: user.phoneNumber,
              fullName: user.displayName,
              email: user.email,
              ...defaultData,
            }}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <div>
                <Form>
                  <Row>
                    <Col md={12}>
                      <Field
                        placeholder="Full Name"
                        as={Input}
                        name="fullName"
                        className={"me-2"}
                        invalid={errors.fullName}
                      />
                      {errors.fullName && touched.fullName ? (
                        <FormFeedback className="text-start">
                          {errors.fullName}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  {/* <Row className="mt-3">
                    <Col>
                      <Field
                        placeholder="Email Id"
                        as={Input}
                        name="email"
                        className={"me-2"}
                        invalid={errors.email}
                      />
                      {errors.email && touched.email ? (
                        <FormFeedback className="text-start">
                          {errors.email}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row> */}
                  <Row className="mt-3">
                    <Col>
                      <Field
                        as={Input}
                        name="mobileNumber"
                        className={"me-2"}
                        disabled="true"
                      />
                    </Col>
                  </Row>

                  <div className="mt-3 d-flex justify-content-start">
                    <FormGroup check>
                      <Field
                        as={Input}
                        type="checkbox"
                        name="tnc"
                        className={"me-2"}
                        invalid={errors.tnc}
                      />
                      <Label check>
                        I have read and I agree to the{" "}
                        <a
                          href="https://fotoowl.ai/privacypolicy/"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </Label>
                    </FormGroup>
                  </div>

                  <div className="mt-4 w-100">
                    <button
                      type="submit"
                      className="btn btn-fotoowl btn-rounded-2 w-100"
                      disabled={isSubmitting}
                    >
                      <strong>Create My Profile</strong>
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CompleteProfile;
